.in-logic {
    color: green;
}

.out-logic {
    color: red;
}

.semi-logic {
    color: orange;
}