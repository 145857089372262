/* 
    Does this file need to exist? No.
    Does the tracker break if you get rid of it? Yes.
    ¯\_(ツ)_/¯

*/
#Sword-item {
    position: absolute;
}

#Flame-item {
    position: absolute;
}


#Beetle-item {
    position: absolute;
}

#Slingshot-item {
    position: absolute;
}

#Bombs-item {
    position: absolute;
}

#GustBellows-item {
    position: absolute;
}

#Whip-item {
    position: absolute;
}

#Bow-item {
    position: absolute;
}

#Clawshots-item {
    position: absolute;
}

#Bugnet-item {
    position: absolute;
}

#Courage-item {
    position: absolute;
}

#Power-item {
    position: absolute;
}

#Wisdom-item {
    position: absolute;
}

#Ballad-item {
    position: absolute;
}

#Soth-item {
    position: absolute;
}

#Harp-item {
    position: absolute;
}

#Sailcloth-item {
    position: absolute;
}

#Scale-item {
    position: absolute;
}

#Earrings-item {
    position: absolute;
}

#Mitts-item {
    position: absolute;
}

#Stone-item {
    position: absolute;
}

#Tablet-item {
    position: absolute;
}

.item {
    position: absolute;
}

.item-container {
    outline-style: none;
}