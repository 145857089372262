.complete {
    text-decoration: line-through;
}

#dungeonTracker p {
    font-family: HyliaSerif;
    font-size: 2.3vw;
    cursor: pointer;
    width: 100%;
}

#dungeonTracker {
    text-align: center;
}